import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 8,
      prop: 'shop_id',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('table.common.shop'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.product.name'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'is_shipping_point',
      value: true,
      name: 'switch',
      label: i18n.t('form.store.shippingPoint'),
      Object: {}
    }, {
      span: 24,
      prop: 'support_pickup',
      name: 'switch',
      value: true,
      label: i18n.t('form.store.supportPickup'),
      Object: {}
    }, {
      span: 8,
      prop: 'operation_hours',
      name: 'time',
      value: ['', ''],
      label: i18n.t('form.store.operationHours'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        'option-format': 'HH:mm',
        'is-range': true,
        format: 'HH:mm'
      }
    }, {
      span: 24,
      prop: 'remote_image_url',
      name: 'image',
      value: [],
      label: i18n.t('form.product.image'),
      Object: {
        limit: 1
      }
    }, {
      span: 8,
      prop: 'phone_number',
      name: 'input',
      label: i18n.t('form.store.phoneNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'lon',
      name: 'input',
      type: 'number',
      label: i18n.t('form.store.longitude'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'lat',
      name: 'input',
      type: 'number',
      label: i18n.t('form.store.latitude'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'address',
      name: 'input',
      label: i18n.t('form.store.address'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }]
  }, {
    title: i18n.t('form.store.entityInformation'),
    name: 1,
    createDateItem: [{
      span: 8,
      prop: 'poiable_type',
      name: 'select',
      options: [],
      label: i18n.t('form.store.offlineEntityType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }]
  }]
}
